.header-bar {
  display: flex;
  flex-direction: row;
  background-color: #293241;
  grid-area: header;
  padding: 10px;
  text-align: center;
  justify-content: flex-start;
}

.header-bar-left {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
}

.header-bar-right {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
  margin-left: auto;
}

.menu-item {
  height: 100%;
  padding: 0;
  color: #E0FBFC;
  line-height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
}


.muted {
  color: red;
  width: 4vw;
  height: 4vw;

}

.unmuted {
  color: white;
  width: 4vw;
  height: 4vw;

}


h4 {
  margin: 0;
}