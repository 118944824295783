.button {
    height: 6vw;
    width: 6vw;
    display: flexbox;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    border: 0px solid #fff;
    border-radius: 3px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    font-size: 0.96vw;
    margin: 0;
}

.button:hover {
  background-color: #d3d3d3;
  cursor: pointer;
  color : crimson;
  transition: color 0.1s linear;
}

.button:active {
  transition: all 25ms linear;
  transform: translateY(4px);
  box-shadow: none;
}
.button-active {
  border: 2px solid crimson;
  outline: none;
}



.button .button-icon {
    width: 3.12vw;
    height: 3.12vw;
    justify-content: center;
    margin: 0%;
}

.button p {
  font-size: 0.96vw;
  margin: 0%;
}

/* Tooltip text */
.button .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition-property: opacity;
    transition-delay: 1000ms;
    transition-duration: 300ms;
    pointer-events: none;
  }
  
  /* Tooltip arrow */
  .button .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;

    pointer-events: none;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .button:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  @keyframes tooltip {
  80% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}