
.button-group {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    margin-top:  2rem;
    margin-left: rem;
    justify-content: center;
    align-content: space-between;
}