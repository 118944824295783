:root {
    --menu-bg: #3D5A80
}

.menu-bar {
    background-color: var(--menu-bg);
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-area: side;
}

#side-wrapper {
    margin: 5px;
    width : 100%
}

#mode-header {
    display: flexbox;
    width: 100%;
    height: max-content;
    text-align: center;
}