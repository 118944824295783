.util-bar-root {
    width: 3vw;
    height: auto;
    background-color: rgb(61, 90, 128);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    position: relative;
}

.util-bar-item-icon {
    font-size: 30px;
    color: #ed6b4d;
    cursor: pointer;
    padding-top: 20px;
}

.util-bar-item-root {
    
}

.util-bar-active-menu {
    position: absolute;
    left: -250px;
    top: 0;
    width: 250px;
    height: 100%;
    background-color: #3189c1;
}